import React from 'react'
import logo from "../image/logo.svg"
import { HiOutlineMenuAlt3 } from "react-icons/hi"
import { Button } from 'react-bootstrap'
import {BiMenuAltLeft} from "react-icons/bi"
import { Link } from 'react-router-dom'

const Header = (props) => {
  const hospitalName = localStorage.getItem('hospital')

  // console.log(props);
  return (
    <div className='header'>
      <div className="head">
        <Link to="/"><img src={logo} className="logo" alt="" /></Link> 

        <div className='d-flex align-items-center menu-heading'>
          <div className='head-title hide-768'>
            <h4>EIDO Discharge Dashboard</h4>
            <h3> {hospitalName} </h3>
            {/* {hospitalName} */}
          </div>
          <Button onClick={() => props.setToggle(!props.toggle)}>
            <HiOutlineMenuAlt3 className='menu hide-768' /> 
            <BiMenuAltLeft className="menu show-768"  />
          </Button>

          <div className='head-title show-768'>
            <h3>Patient Discharge Document</h3>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header