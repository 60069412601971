import React, { useState, useRef, useEffect } from "react";

import Header from './Header'
import Sidebar from './Sidebar'

const Layout = (props) => {

    const [toggle, setToggle] = useState()

    const ref = useRef();

    useEffect(() => {
        const checkoutside = (e) => {
            if (toggle && ref.current && !ref.current.contains(e.target)) {
                setToggle(false)
            }
        };
        document.addEventListener("click", checkoutside);
        return () => {
            document.removeEventListener("click", checkoutside);
        };
    }, [toggle]);

    // console.log( "click")


    return (
        <>
            <div className='dashboard-main '   ref={ref}  >
                {
                    <Header setToggle={setToggle} toggle={toggle} />
                }

                <div className={`${!toggle ? "pe-6rem" : " home"} home`} >
                    {props.children}
                </div>
            </div>

            <Sidebar toggle={toggle} />
        </>
    )
}

export default Layout