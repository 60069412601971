import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// import Loginpage from './pages/Loginpage';
import { Routes, Route, Navigate, Outlet, } from "react-router-dom";
import Loginpage from './pages/Loginpage';
import Header from './component/Header';
import Layout from './component/Layout';
import Home from './pages/Home';
import Patienteducation from './pages/Patienteducation';
import { AuthState } from "./context/authContext";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import setAxiosUrl from './API/api';
import OpenPdf from './pages/OpenPdf';
import ForgotPassword from './component/ResetPassword';
import ResetPassword from './component/ResetPassword';
import SendDischarge from './component/SendDischarge';
function App() {
  const { user } = AuthState()
  const token = localStorage.getItem("token")
  // console.log(token, "tokenene");
  // console.log(typeof user, "user");

  const ProtectedRoute = ({ token, children }) => {
    if (!token) {
      return <Navigate to={"/"} replace />;
    }

    return children ? children : <Outlet />;
  };



  return (
    <div className="App">
      <ToastContainer />

      <Routes>

        <Route path='/' element={!token ? <Loginpage /> : <Navigate replace to="/Home" />} ></Route>
        <Route path="/forgot-password/:token" element={<ResetPassword />} />
        <Route exact path='/' element={<Loginpage />} ></Route>
        <Route element={<ProtectedRoute token={token} />}>
          <Route exact path="/Home" element={<Home />} />
          <Route path="/Patienteducation" element={<Patienteducation />} />
          <Route path="/SendDischarge" element={<SendDischarge />} />
        </Route>

        <Route path="*" element={<p>There's nothing here: 404!</p>} />

      </Routes>
    </div>
  );
}

export default App;

        {/* <Route exact path="/" element={!user ? <Loginpage /> : <Home />} />  */}


    {/*         
        <Route path='/Loginpage' exact element={(!token ? <Loginpage /> : <Navigate replace to="/Home" />)}></Route>
        <Route path='/' exact element={<Navigate replace to="/Home" />} ></Route>

        <Route exact path="/Loginpage" element={<Loginpage />} />
        <Route exact path="/Home" element={<Home />} />
        <Route exact path="/Patienteducation" element={<Patienteducation />} />
        <Route path="/forgot-password/:token" element={<ResetPassword />} />
        <Route path="/SendDischarge" element={<SendDischarge />} /> */}