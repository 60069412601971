import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Layout from '../component/Layout'
import { AiFillCaretDown, AiOutlineQuestionCircle } from "react-icons/ai"
import { ImDownload3 } from "react-icons/im"
import Table from 'react-bootstrap/Table'
import setAxiosUrl, { getFilteredData } from '../API/api'
import moment from 'moment'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import OpenPdf from './OpenPdf'
import LoadingSpinner from '../component/LoadingSpinner/LoaderSpinner'
function Patienteducation() {
    const [doc_id, setDoc_id] = useState()
    const navigate = useNavigate()
    const [month, SetMonth] = useState(["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"])
    const [listOfRangePeriod, setListOfRangePeriod] = useState([
        'Last Year Data',
        'Current Year Data',
        'Last Six Months Data',
        'Last Month Data',
        'Last Week Data',
        'Current Week Data',
    ]);
    let defaultValueSelect;
    const [selectedData, setSelectedData] = useState()
    const [selectFromDate, setSelectFromDate] = useState()
    const [selectToDate, setSelectToDate] = useState()
    const [getExcel, setGetExcel] = useState(false)
    const [getList, setList] = useState(false)
    const [loading, setLoading] = useState(false)
    let data;
    const checkStatus = selectedData?.status === 1;
    const getArrayLength = checkStatus ? Object.values(selectedData?.data).length : "" 

    const handleGenerateExcelData = async () => {
        console.log(getExcel, "-----");
        data = await getFilteredData({
            'download': "true",
            "from": selectFromDate?.toString(),
            "to": selectToDate?.toString(),
            "range": doc_id,

        })
        const kk = data?.download?.length > 0 ?
            window.open(`${setAxiosUrl.defaults.baseURL}${data.download}`, '_blank') : ""
        // setDownload(false)
    }

    const handleCalenderData = async () => {

        if (selectFromDate && selectToDate !== "") {
            setList(true)
        }
        setLoading(true)

        data = await getFilteredData({
            "from": selectFromDate?.toString(),
            "to": selectToDate?.toString(),
            "range": doc_id,
        })
        setSelectedData(data)
        setGetExcel(data?.download)
        setLoading(false)

    }
    useEffect(() => {
        handleCalenderData()
        // setSelectedData(" ")

    }, [selectToDate, selectFromDate, listOfRangePeriod, doc_id])


    // console.log(defaultValueSelected, "default");
    const handleClickButton = (e, value) => {
        e.preventDefault();

        // <Link to={`${setAxiosUrl.defaults.baseURL}${value.pdf_formate}`}></Link>
        // navigate(`${setAxiosUrl.defaults.baseURL}${value.pdf_formate}`)
        // <OpenPdf value={ window.open(`${setAxiosUrl.defaults.baseURL}${value.pdf_formate}`)}/>
        // <OpenPdf value={window.open(`${setAxiosUrl.defaults.baseURL}${value.pdf_formate}`, '_blank')}  changeFavicon={changeFavicon("http://www.google.com/favicon.ico")}/>

        window.open(`${setAxiosUrl.defaults.baseURL}${value.pdf_formate}`, '_blank')
    }

    return (
        <Layout>
            <div className='main-table-page'>
                <div>
                    <form>
                        <Row className='justify-content-center  my-2 '>

                            <Col xxl={6} xl={8} lg={8}>
                                <div className="report-table main-model-body">
                                    <div className="d-flex justify-content-between">
                                        <h4>Report Period</h4>
                                        <div className='d-flex'>
                                            <h4 className='me-3 filter' onClick={(e) => { setSelectToDate(""); setSelectFromDate(""); setDoc_id(""); setSelectedData(""); }}> <b>Clear</b> </h4>
                                            <h4 className='filter'
                                            //  onClick={(e) => { setList(true); }}
                                            >
                                                <b>Filter</b>
                                            </h4>
                                            <h4 className='export ms-3'
                                                onClick={getArrayLength ? (e) => handleGenerateExcelData():""}
                                            >
                                                {/* {
                                                    getExcel?.length>0 ?
                                                    <Link to={`${setAxiosUrl.defaults.baseURL}/${getExcel}`}>Download</Link>
                                                    :
                                                    <Link to="" >Download</Link>

                                                } */}
                                                <b>Export</b>
                                            </h4>
                                        </div>
                                    </div>

                                    <div className="select-op">
                                        <span className='range'>Range</span>
                                        <AiFillCaretDown className='down-arrow' />

                                        <select value={doc_id} onChange={e => setDoc_id(e.target.value)} name="" id="">
                                            <option value="" disabled selected><span>This Calendar Year</span></option>

                                            {
                                                listOfRangePeriod.map((list, val) => {
                                                    return (
                                                        <option value={list._id} key={val}> {list} </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <Row className='mt-3'>
                                        <Col xxl={6}>
                                            <div className="date-box  select-op">
                                                <span>From</span>
                                                {/* <AiOutlineCalendar className='date-icon' /> */}
                                                <input type="date" name="from" id="" className='date' value={selectFromDate} onChange={(e) => { setSelectFromDate(e.target.value) }} required />
                                            </div>
                                        </Col>
                                        <Col xxl={6}>
                                            <div className="date-box select-op">
                                                <span>To</span>
                                                {/* <AiOutlineCalendar className='date-icon' /> */}
                                                <input type="date" name="to" id="" className='date' value={selectToDate} onChange={(e) => { setSelectToDate(e.target.value) }} required />
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="d-flex justify-content-between mt-5 mb-3">
                                        <h4>Procedure</h4>
                                        <h4 ><b>Filter</b></h4>
                                    </div>
                                    <div className='scroll-table'>

                                        {
                                            // getList == true &&
                                            getArrayLength ?
                                                selectedData?.status === 1 && Object.keys(selectedData?.data).length > 0 && Object.values(selectedData?.data)?.map((value) => (
                                                    <>
                                                        {/* {console.log(value)} */}
                                                        {getArrayLength ?
                                                            <div className="procedure ">
                                                                <div className='procedure-box' >
                                                                    <div className=' procedure-content'>
                                                                        <div className='width-box'> <p>{value.code}</p> </div>&nbsp;&nbsp;
                                                                        <div className='width-box1'> <p>{value.title}</p> </div>&nbsp;&nbsp;
                                                                        <div className='d-flex'> <p className='nowrap'> <span>{new Date(value.created_at).getDate()} </span>&nbsp;-&nbsp;<span>{month[new Date(value.created_at)?.getMonth()]} </span> &nbsp;-&nbsp;<span> {new Date(value.created_at)?.getFullYear()}  </span>&nbsp;|&nbsp; <span>  {moment(value.created_at).format("hh")}:{moment(value.created_at).format("mm")} </span></p> &nbsp; </div>
                                                                    </div>
                                                                    {/* <span> |</span>&nbsp;  */}
                                                                    {/* <span> </span><span>{value.email}</span> */}
                                                                    {/* <a href={`http://192.168.40.160:5000/${value.pdf_formate}`} target="_blank">
                                                             <h5>See Pdf
                                                             </h5>
                                                            </a>  */}
                                                                    <h5 onClick={(e) => handleClickButton(e, value)}>See <span> Pdf</span>
                                                                    </h5>
                                                                </div>
                                                            </div> : <><p>hello</p></>}
                                                    </>
                                                ))
                                                :
                                                loading ? <h1 className='lod'>
                                                    <LoadingSpinner/>
                                                </h1> :
                                                    <><h5 className='data-not'>Data Not Found</h5></>
                                        }
                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </form>
                </div>
                <div className="d-flex justify-content-end  position-help">
                    {/* <a href="mailto:rohan.vasundhara19@gmail.com"><AiOutlineQuestionCircle  />Help </a> */}
                    <button className='help-btn mx-4 mb-0 sm-mb-5'> <a className='text-white text-decoration-none' href="mailto:kayla.blewett@eidohealthcare.co.za"><AiOutlineQuestionCircle />Help </a></button>
                </div>
            </div>
        </Layout>
    )
}

export default Patienteducation














// useEffect(() => {
//     const date = new Date();
//     const futureDate = date.getDate();
//     date.setDate(futureDate);
//     defaultValueSelect = date.toLocaleDateString('en-ca');
//     console.log(defaultValueSelect);
//     setDefaultValueSelected(defaultValueSelect)
//     setSelectFromDate(defaultValueSelect)
//     setSelectToDate(defaultValueSelect)
// }, [])











