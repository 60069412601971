import React, { useState, useContext, useEffect } from 'react'
import { WiTime8 } from "react-icons/wi"
import { RiShieldUserLine } from "react-icons/ri"
import { AiOutlineLogout, AiOutlineHome } from "react-icons/ai"
import { Link, useNavigate } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { toast } from "react-toastify";
import { adminLogin } from '../API/api'
import { AuthState } from '../context/authContext'
import iconone from "../image/Sidebar-img/1.svg"
import icontwo from "../image/Sidebar-img/2.svg"
import iconthree from "../image/Sidebar-img/3.svg"
import iconfour from "../image/Sidebar-img/4.svg"
import { UserContext } from '../pages/Loginpage'
import SendDischarge from './SendDischarge'
// const [sideactive, setSideactive] = useState("");

const Sidebar = (props) => {
  const { saveResponse, setSaveResponse, user, show, setShow, modalShow, setModalShow, setDisappearingButton, getObjectForDisplay, setGetObjectForDisplay } = AuthState()
  const getFirstAlpha = localStorage.getItem('first_alpha')
  // console.log(getFirstAlpha,"P");
  // console.log(saveResponse,user);
  // const user = useContext(UserContext);
  const [showLogout, setShowLogout] = useState(false);

  const handleClose = () => setShowLogout(false);
  const handleShow = () => setShowLogout(true);
  const navigate = useNavigate()



  const handlePopup = (e) => {
    // console.log(modalShow,setModalShow);
    // console.log(getObjectForDisplay);
    e.preventDefault()
    // console.log(show, modalShow);
    navigate("/Home")
    // setDisappearingButton(true)
    // setGetObjectForDisplay(true)
    setShow(!show)
    setModalShow(!modalShow)
    // setDisappearingButton(false)
    // setShow(!show)
    // <SendDischarge/>
  }

  const LogoutHandler = () => {
    localStorage.clear()
    toast.success("Logout successfully")
    // navigate("/Loginpage")
  }

  const getName = localStorage.getItem('name')

  return (
    <div>
      {/* desktop view sidebar  */}
      {/* <div className={`${props.toggle ? "sidebar " : " hide-sidebar"} sidebar  `}> */}
      <div className={`${props.toggle ? "sidebar " : "hide-sidebar "} sidebar  `}>
        <ul>
          <li className='add-px d-flex align-items-center'>
            <div className="Ab t">{saveResponse?.first_alpha || getFirstAlpha}</div>
            <div className='side-text  '>{getName}</div>
          </li>

          <li className='add-px d-flex align-items-center'>
            <Link className="d-flex align-items-center" to="/" >
              <div className="Ab2"><img src={iconone} className="img-fluid" alt="" /></div>
              <div className='side-text'>Home</div>
            </Link>
          </li>

          <li className='add-px d-flex align-items-center'>
            <Link className=" d-flex align-items-center" to="/Patienteducation">
              <div className='Ab2 '><img src={icontwo} className="img-fluid" alt="" /> </div>
              <span className='side-text'> Report </span>
            </Link>
          </li>

          <li className='add-px d-flex align-items-center'>
            {/* <Link to="/Home" className="d-flex align-items-center"> */}
            <div onClick={handlePopup} className="d-flex align-items-center">
              <div className="Ab2"><img src={iconthree} className="img-fluid" alt="" /></div>
              <span className='side-text'> Send discharge document</span>
            </div>
            {/* </Link> */}

          </li>

          <li className='add-px d-flex align-items-center' onClick={handleShow}>
            {/* <Link className="d-flex align-items-center" to="">   */}
            <div className="Ab2"><img src={iconfour} className="img-fluid" alt="" /></div>
            <div className='side-text'>Logout</div>
            {/* </Link>  */}
          </li>

        </ul>
      </div>



      {/* MObile view sidebar  */}

      <div>

        <div className={`${props.toggle ? "back-screen " : "no-screen"} no-screen`}    >
        </div>

        <div className={`${props.toggle ? " " : "hide-sidebar"} sidebar sidebar-block`}>
          <ul>
            <div className="Ab">{saveResponse?.first_alpha || getFirstAlpha}</div>
            <li> <Link to="/"><img src={iconone} className="img-fluid" alt="" /> Dashboard</Link>  </li>
            <li>   <Link to="/Patienteducation"> <img src={icontwo} className="img-fluid" alt="" /> Report </Link>  </li>
            <li>    <Link to="" onClick={handlePopup}> <img src={iconthree} className="img-fluid" alt="" />  Send discharge document</Link>  </li>
            <li onClick={handleShow}> <img src={iconfour} className="img-fluid" alt="" /> Logout    </li>
          </ul>
        </div>
      </div>

      {/* logout model ======== */}

      <Modal show={showLogout} onHide={handleClose} className='logout-model' centered>
        <Modal.Header closeButton>

          <div className='model-head'>
            <Modal.Title id="example-custom-modal-styling-title">
              Logout
            </Modal.Title>
          </div>

        </Modal.Header>
        <Modal.Body>
          <h4> Are you sure you want to logout?
          </h4>
          {/* <h4>  You want to logout in EIDO</h4> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Link to="/">
            <Button variant="primary" onClick={LogoutHandler}>
              Logout
            </Button>
          </Link>

        </Modal.Footer>
      </Modal>
    </div>

  )
}

export default Sidebar