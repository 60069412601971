import React from 'react'
// import Select from 'react-select/dist/declarations/src/Select';
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import { toast } from "react-toastify";
import Select, { createFilter } from 'react-select';
import { AiOutlineQuestionCircle, AiFillCaretDown } from "react-icons/ai"

const SendDischarge = ({ getSelectedforOption, getValueForOption, handleSelectValues, setModalCon }) => {

    const customStyles = {
        control: (styles, { isDisabled }) => {
            return {
                ...styles,
                cursor: isDisabled ? 'not-allowed' : 'default',
                color: isDisabled ? '#aaa' : 'white',
                padding: "10px",
                borderRadius: "0px",
                border: "none",
                fontSize: "20px",
                //   borderColor:"#d7d7d7",
                boxShadow: "none"


            }
        },
        option: (styles, { isDisabled }) => {
            // console.log(isDisabled);
            return {
                ...styles,
                backgroundColor: isDisabled ? '#00b7ff' : "",
                padding: "10px",
                display: "flex",
                wordSpace: "80px",
                color: isDisabled ? '#FFF' : '#000',
                cursor: isDisabled ? 'not-allowed' : 'default',
            };
        },
    }

    const filterConfig=()=>{
        
    }

    return (
        <>

            <div className='send-search'>
                <Container className=''>
                    <h4>Search & Send</h4>
                </Container>
            </div>
            <div className='main-model-body select-padding'>
                <Container>
                    <form >
                        <div className="artical">
                            <h5>Send an EIDO Discharge Document to your patient via email</h5>
                            <p>Search in the box below by procedure name or EIDO code.</p>
                        </div>
                        <div className='droupdown'>
                            <h4>Which EIDO document would you like to send?</h4>
                        </div>



                        {/*  new select */}
                        <div className='new-select'>

                            <Select classNamePrefix="lp-copy-sel" options={getValueForOption}
                                // getOptionLabel={(option) => `${option.label}  ${option.code}`}   
                                getOptionLabel={(option) => {
                                    return <>
                                        {/* {option.label}  &nbsp;&nbsp;  {option.code} */}
                                        <div className="new-option">
                                            <div className='first-lable'>
                                                {option.label}
                                            </div>
                                            <div className='second-lable'>
                                                {option.code}
                                            </div>
                                        </div>

                                        {/* &nbsp; {(`${option.label}  ${option.code}`)} */}
                                    </>


                                }}
                                filterOption={createFilter(filterConfig)}
                                isSearchable={true}
                                getOptionValue={option => `${option}`}
                                // value={Object.values(getSelectedforOption).length>0  ?getSelectedforOption:{"label":"Start typing for a list of matching procedures"} }
                                placeholder={'Start typing for a list of matching procedures '}
                                // value={getSelectedforOption}
                                onChange={handleSelectValues} styles={customStyles} name="selected" id=""
                                // noOptionsMessage={() => null}
                                // getOptionSelected={(option, value) => option.code === value.code }
                                isClearable
                            />

                            <AiFillCaretDown className='down-arrow' />

                        </div>
                        <div className={Object.values(getSelectedforOption).length > 0 ? "code-box" : "d-none"}  >

                            <div className='code-table d-flex align-items-center justify-content-between'>

                                <div className=''>
                                    <h5>EIDO Procedure Information</h5>
                                    <span>{getSelectedforOption.label}</span>
                                </div>
                                <div className=''>
                                    <h5>EIDO Code</h5>
                                    <span>{getSelectedforOption.code}</span>
                                </div>
                                {/* <Button className='delete-btn'><img src={deleteimg} onClick={() => {  
                                                                                setGetSelectedOption({}) }} className="delet" alt="" width={20} /></Button> */}


                            </div>
                        </div>
                        <div className='d-flex justify-content-end model-btn'>
                            <button className='send' onClick={(e) => {
                                e.preventDefault();
                                if (Object.values(getSelectedforOption).length <= 0) {
                                    toast.error("Please Select Document ")
                                    return
                                }
                                if (getSelectedforOption.checkPdfStatus == "") {
                                    toast.error("This document is without pdf file")
                                }
                                else 
                                setModalCon(false);

                            }}>
                                EMAIL TO PATIENT</button>
                        </div>
                    </form>
                </Container>
            </div>

            <div className="d-flex justify-content-end  position-help">
                                     {/* <a href="mailto:rohan.vasundhara19@gmail.com"><AiOutlineQuestionCircle  />Help </a> */}
                                     <button className='help-btn mx-4 mb-0 sm-mb-5'> <a className='text-white text-decoration-none' href="mailto:kayla.blewett@eidohealthcare.co.za"><AiOutlineQuestionCircle />Help </a></button>
                                </div>

        </>
    )
}

export default SendDischarge