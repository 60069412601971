import axios from 'axios'

let setAxiosUrl = axios.create({
  // baseURL: "http://192.168.40.108:5000/",
    baseURL: `${window.location.origin}/`,
});
setAxiosUrl.interceptors.request.use((req) => {
  if (localStorage.getItem('token')) {
    req.headers.Authorization = `Bearer ${localStorage.getItem("token")}`
  }
  return req
})
export default setAxiosUrl;

const errorResponse = (error) => {
  error.response
    ? console.error("API", error.response.data.message)
    : console.error("API", error.message);
};


//Sign-in
export const adminLogin = async (email, password) => {
  try {
    const res = await setAxiosUrl.post(`api/sign-in`, {
      email,
      password,
    });
    return res.data;
  } catch (error) {
    errorResponse(error);
    return error.response ? error.response.data : error.message;
  }
};

export const getDiseaseData = async () => {
  try {
    const res = await setAxiosUrl.get(`api/get-all-disease-data`);
    // console.log(res);
    return res.data;
  } catch (error) {
    errorResponse(error);
    return error.response ? error.response.data : error.message;
  }
};

export const creatPatientData = async (data, toast, navigate) => {
  console.log(data,"dataaa");
  try {
    const res = await setAxiosUrl.post(`api/patient-discharge-data`,
      data);
    // console.log(res.data, "res");
    if (res.data.status === 1) {
      toast.success(res.data.message)
      navigate("/Home")
    }
    // else {
    //   toast.error(res.data.message)
    // }

    return res.data;
  } catch (error) {
    errorResponse(error);
    return error.response ? error.response.data : error.message;
  }
};


export const getFilteredData=async(data)=>{
  console.log(data,"datagetfiltered");
  let selectedDate={}
  let finalValue
  if(data?.range){
    delete data.to
    delete data.from
  }
  if(data?.to || data?.from){
    selectedDate.to=data?.to
    selectedDate.from=data?.from
    selectedDate.download=data?.download
  }

 const condition = data.to || data.from ? selectedDate:data 
  if(data?.range || data?.from || data?.to){
    finalValue=condition
  }
  else{
    finalValue={}
  }
  // if(condition?.range==undefined){
  //   delete condition.range
  // }
  console.log(condition);
  try{
    if(Object.keys(finalValue).length!==0){
      const res = await setAxiosUrl.post(`api/get-all-patient-data`,condition)
      console.log(res.data,"res");
      return res.data
    }
  }catch(error){
    // console.log(error);
    errorResponse(error);
    return error?.response ? error?.response.data : error?.message;
  }
}



export const ForgotPassword = async (email) => {
  // console.log(email,"dataaa");
  try {
    const res = await setAxiosUrl.post(`api/forgot-password`,
      {email});

    return res.data;
  } catch (error) {
    errorResponse(error);
    return error.response ? error.response.data : error.message;
  }
};

export const resetPassword = async (requestData,setGetCondition) => {
  
  try {
    const res = await setAxiosUrl.post(`api/update-password`,
      requestData);
    setGetCondition(res.data)
  
    return res.data;
  } catch (error) {
    errorResponse(error);
    return error.response ? error.response.data : error.message;
  }
};