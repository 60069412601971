import {createContext,useContext,useEffect,useState} from 'react'
import {useNavigate} from 'react-router-dom'
const AuthContext=createContext()

const AuthProvider=({children})=>{
    const[user,setUser]=useState()
    const[saveResponse,setSaveResponse]=useState()
    const [getDiseaseFrom,setGetDiseaseFrom]=useState()
    const [getCondition,setGetCondition]=useState()
    const  [show,setShow] = useState(false)
    const [modalShow, setModalShow] = useState(false);
    const [disappearingButton,setDisappearingButton] = useState()
    const [getObjectForDisplay,setGetObjectForDisplay] = useState(false)
    const navigate=useNavigate()
    useEffect(()=>{
        const userInfo=localStorage.getItem('token')
        // console.log(userInfo,"userinfo");
        setUser(userInfo);
        // if(!userInfo) navigate('/')
    },[navigate])
   
    return(
        <AuthContext.Provider value={{user,setUser,saveResponse,setSaveResponse,getDiseaseFrom,setGetDiseaseFrom,getCondition,setGetCondition,show,setShow,modalShow,setModalShow,disappearingButton,setDisappearingButton,setGetObjectForDisplay,getObjectForDisplay}}>
            {children}
        </AuthContext.Provider>
    )
}
export const AuthState=()=>{
    return useContext(AuthContext)
}

export default AuthProvider