import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import logo from '../image/logo.svg'
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai"
import passwordimg from '../image/Homeimg/password.svg'
import { toast } from "react-toastify";
import { resetPassword } from '../API/api';
import { AuthState } from '../context/authContext';

const ResetPassword = () => {
  const {setGetCondition} = AuthState()
  const navigate = useNavigate();
  const params = useParams()

  const [new_password, setNew_password] = useState("")
  const [confirm_password, setConfirm_password] = useState("")

  const onSignIn =async (e) => {
    e.preventDefault()
    if (!new_password || !confirm_password) {
      toast.error("Please fill field")
      return
    }

    if (!(new_password.length >= 5)) {
      toast.error("Set your new password greater than 6 characters")
      return
    }

    if (new_password !== confirm_password) {
      toast.error("Your new password is not match with confirm password")
      return
    }

    const requestData = {
      token: params.token,
      password: new_password,
    }

    const resetPasswordResponse = await resetPassword(requestData,setGetCondition)

    const { status, message, name, token } = resetPasswordResponse
    if (status === 1) {
      toast.success(message)
      navigate("/")
    } else {
      toast.error(message)
    }

  }


  return (
    <div>

      <div className="login-main-box">
        <div className="login-box">

          <div className="logo">
            <img src={logo} alt="logo" />
          </div>

          <div className="main-form">
          

          <form className="mt-4 form-reset">

            <h1><b1>Reset Password</b1></h1>
            <div className=" mt-5  input-box">
              <label className='reset-form-label'>New Password</label>
              <input
              className='ps-0 mb-0'
                type="password"
                placeholder="Enter new password"
                onChange={e => setNew_password(e.target.value)}
                value={new_password}
                autoComplete="off"
              />
            </div>
            <div className=" mt-4 mb-5 ">
              <div className="password_main w-100 input-box text-start d-flex">
                <label className='reset-form-label'>Confirm Password</label>
                <input
                className='ps-0 mb-0'
                  type="password"
                  placeholder="Enter Confirm Password"
                  onChange={e => setConfirm_password(e.target.value)}
                  value={confirm_password}
                  autoComplete="off"
                />

              </div>
            </div>
            <Button
              type="submit"
              onClick={onSignIn}
              className="signin_btn"
            >
              Change Password
            </Button>
          </form>
          </div>
        </div>

      </div>
    </div>
  )
}

export default ResetPassword