import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Button, Col, Container, Row, Spinner, Table } from 'react-bootstrap';
import { AiOutlineQuestionCircle, AiFillCaretDown } from "react-icons/ai"
// import Table from 'react-bootstrap/Table'
import { RiDeleteBinLine } from "react-icons/ri"
import { BiErrorCircle } from "react-icons/bi"
import { getDiseaseData, creatPatientData } from '../API/api'
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom'
import deleteimg from "../image/Homeimg/delete.svg"
import validator from 'validator';
import { AuthState } from '../context/authContext';
import Select, { createFilter } from 'react-select';
import SendDischarge from '../component/SendDischarge';


function Patientmodel({ showVal, handleToggle }) {
    const navigate = useNavigate()
    var createdData
    var selectedValue;
    const { show, setShow, disappearingButton, setDisappearingButton, setGetObjectForDisplay, getObjectForDisplay } = AuthState()
    // const [show, setShow] = useState(false);
    const [selectedDisease, SetSelectedDisease] = useState([])
    const [modalCon, setModalCon] = useState(true);
    const [isLoading, setIsLoading] = useState(false)
    const [handleDataDocument, setHandleDataDocument] = useState([])
    const [getSelectedforOption, setGetSelectedOption] = useState({})
    const [getValueForOption, setGetValueForOption] = useState([])
    const [selectSearchText, setSelectSearchText] = useState()
    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        bcc: ""
    })
    const [doc_id, setDoc_id] = useState()

    const gettingCheckedPdfFiles = getSelectedforOption ? getSelectedforOption.length > 0 : ""
    let array = []
    if (gettingCheckedPdfFiles) {
        array = getSelectedforOption?.filter((value) => {
            return value.checkPdfStatus == ""
        })
    }

    const gettingLabel =  array.map((value)=>value.label)
    console.log(gettingLabel.toString());
  

    let arrayOfSelected = getValueForOption?.length > 0 && Object.values(getValueForOption)
    // let arrayOfSelected =  Object.values(getValueForOption?.options.length>0)  ? Object.values(getValueForOption?.options):[]
    const filterConfig = (option, search) => {
        // const getResponse = getValueForOption.filter((selectedValue)=> (selectedValue.label===search || selectedValue.code===search ))

        const booleanValue = option.data.label.toLowerCase().includes(search.toLowerCase()) || option.data.code.toLowerCase().includes(search.toLowerCase())
        if (
            booleanValue
        ) {
            return true
        }
        else {
            return false
        }
    }
    let handleClose = () => {
        setShow(false);
        handleToggle()
        setModalCon(true)
        SetSelectedDisease([])
        setDoc_id("")
        setFormData({
            first_name: "",
            last_name: "",
            email: "",
            bcc: ""
        })
        setGetSelectedOption({})
        setSelectSearchText("")
        setDisappearingButton(false)
        // SetSelectedDisease([])
    }
    useEffect(() => {

        setShow(showVal)
        // setGetObjectForDisplay(false)
        handleDataOfDisease()
        // const selected = Object.values(handleDataDocument).filter((valueOfDocuments) => {
        //     return valueOfDocuments._id === doc_id
        // })
        let selectedOption
        if (arrayOfSelected.length > 0) {
            selectedOption = Object.values(arrayOfSelected).filter((valueOfDocuments) => {
                return valueOfDocuments.value === getSelectedforOption.value
            })
        }
        // console.log(selectedOption, "data");
        SetSelectedDisease(selectedOption)

        // setGetDiseaseFrom(selected)
        setModalCon(true)
        // console.log(getObjectForDisplay);

    }, [showVal, doc_id, getSelectedforOption?.value, getObjectForDisplay]);

    useEffect(() => {
        let options = handleDataDocument?.map((allData) => ({
            "value": allData._id,
            "label": allData.title,
            "code": allData.code == "title" ? "" : allData.code,
            "isDisabled": allData.code === "title" ? true : false,
            "checkPdfStatus": allData.pdf_formate ? allData.pdf_formate : ""
        }))

        // console.log(options);
        setGetValueForOption(options)
    }, [handleDataDocument])

    const handleDataOfDisease = async () => {
        const data = await getDiseaseData()
        // console.log(data, "collection");
        setHandleDataDocument(data.data)
        // console.log(handleDataDocument);

        let options = handleDataDocument.map((allData) => ({
            "value": allData._id,
            "label": allData.title,
            "code": allData.code == "title" ? "" : allData.code,
            "isDisabled": allData.code === "title" ? true : false,
            "checkPdfStatus": allData.pdf_formate ? allData.pdf_formate : ""
        }))


        // console.log(options);
        setGetValueForOption(options)
    }
    // console.log(handleDataDocument);
    const [modalShowtwo, setModalShowtwo] = useState(false);
    const handleToggletwo = (_potid) => {
        setModalShowtwo(!modalShowtwo);
    };
    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault()

        if (formData) {

            if (!formData.last_name.trim() && !formData.first_name.trim() && !formData.email.trim()) {
                toast.error("Please fill all fields ")
                return
            }

            const getFirstName = formData.first_name.indexOf(' ') >= 0
            if (getFirstName == 1) {
                toast.error("Please enter first valid name")
                return
            }

            const getLastName = formData.last_name.indexOf(' ') >= 0
            if (getLastName == 1) {
                toast.error("Please enter valid last name")
                return
            }

            if (formData.first_name.length == 0 || formData.last_name.length == 0) {
                toast.error("Please enter valid name ")
                return
            }

            if (!validator.isEmail(formData.email.trim())) {
                toast.error("Please enter valid email address")
                return
            }
        }

        setIsLoading(true)
        const getPatientInfo = await submitData()
        // console.log(getPatientInfo, "TTT");
        // setIsLoading(true)
        if (getPatientInfo.status === 0) {

            setIsLoading(false)
            toast.error("something went wrong")
        } else {
            setIsLoading(false)
            SetSelectedDisease([])
            setDoc_id("")
            setShow(false);
            handleToggle()
            setModalCon(true)
            setHandleDataDocument([])
            setModalCon(true)

        }
        setGetSelectedOption({})

        setFormData({
            first_name: "",
            last_name: "",
            email: "",
            bcc: ""
        })

    }
    // console.log(getSelectedforOption);
    const submitData = async () => {
        const allCode = getSelectedforOption.map((value) => { return value.code })
        console.log(allCode);
        return createdData = await creatPatientData({ ...formData, "eido_code": allCode }, toast, navigate)
        // console.log(createdData, "created");
    }
    const handleSelectValues = (e, newValue) => {
        // console.log(e);
        // if(e.target.value!=="title")
        setGetSelectedOption(e)
        setDoc_id(e._id)
    }


    const customStyles = {
        control: (styles, { isDisabled }) => {
            return {
                ...styles,
                cursor: isDisabled ? 'not-allowed' : 'default',
                color: isDisabled ? '#aaa' : 'white',
                padding: "10px",
                borderRadius: "0px",
                border: "none",
                fontSize: "20px",
                //   borderColor:"#d7d7d7",
                boxShadow: "none"


            }
        },
        option: (styles, { isDisabled }) => {
            return {
                ...styles,
                backgroundColor: isDisabled ? '#CBE4EF' : "",
                padding: "10px",
                display: "flex",
                wordSpace: "80px",
                color: isDisabled ? '#FFF' : '#000',
                cursor: isDisabled ? 'not-allowed' : 'default',
            };
        },
    }


    const handleInputChange = (value, e) => {
        if (e.action === "input-change") {
            // setSelectSearchText(value)
        }

    };

    return (
        <div className='patientmodel'>
            <Modal
                show={show}
                onHide={() => setShow(handleClose)}
                dialogClassName="modal-90w"
                className='patientmodel'
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton >
                    <div>
                        <Modal.Title id="example-custom-modal-styling-title">
                            Patient Discharge Document
                        </Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {
                        modalCon ?
                            // <SendDischarge getSelectedforOption={getSelectedforOption} getValueForOption={getValueForOption} handleSelectValues={handleSelectValues} setModalCon ={setModalCon}/>
                            <>

                                {/* modal 1 ============*/}

                                <div className='send-search'>
                                    <Container className=''>
                                        <h4>Search & Send</h4>
                                    </Container>
                                </div>
                                <div className='main-model-body select-padding'>
                                    <Container>
                                        <form >
                                            <div className="artical">
                                                <h5>Send an EIDO Discharge Document to your patient via email</h5>
                                                <p>Search in the box below by procedure name or EIDO code.</p>
                                            </div>
                                            <div className='droupdown'>
                                                <h4>Which EIDO document would you like to send?</h4>
                                            </div>



                                            {/*  new select */}
                                            <div className='new-select'>

                                                <Select classNamePrefix="lp-copy-sel" options={getValueForOption}
                                                    getOptionLabel={(option) => {
                                                        return <>
                                                            <div className="new-option">
                                                                <div className='first-lable'>
                                                                    {option.label}
                                                                    {setSelectSearchText(option.label)}
                                                                </div>
                                                                <div className='second-lable'>
                                                                    {option.code}
                                                                </div>
                                                            </div>
                                                        </>
                                                    }}
                                                    filterOption={filterConfig}
                                                    onInputChange={handleInputChange}
                                                    isSearchable={true}
                                                    // getOptionValue={option => `${option}`}
                                                    placeholder={'Start typing for a list of matching procedures '}
                                                    autoFocus={true}
                                                    onChange={handleSelectValues} styles={customStyles} name="selected" id=""
                                                    isMulti={true}

                                                // value={getSelectedforOption}
                                                // noOptionsMessage={() => null}
                                                // getOptionSelected={(option, value) => option.code === value.code }
                                                // isClearable
                                                // value={Object.values(getSelectedforOption).length>0  ?getSelectedforOption:{"label":"Start typing for a list of matching procedures"} }
                                                // menuIsOpen={}
                                                />

                                                <AiFillCaretDown className='down-arrow' />

                                            </div>
                                            <div className={getSelectedforOption.length > 0 ? "code-box" : "d-none"}  >

                                                <div className='code-table '>
                                                    <div className='information-height'>
                                                        <Table responsive >
                                                            <thead>
                                                                <tr>
                                                                    <th> <h5>EIDO Procedure Information</h5></th>
                                                                    <th>  <h5>EIDO Code</h5></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    getSelectedforOption.length > 0 && getSelectedforOption?.map((value, id) => (
                                                                        <>
                                                                            <tr key={id}>
                                                                                <td><span>{value?.label}</span></td>
                                                                                <td><span>{value?.code}</span></td>

                                                                            </tr>
                                                                        </>))
                                                                }
                                                                {/* <tr>
                                                                <td>Information</td>
                                                                <td>Information</td>
                                                            </tr> */}

                                                            </tbody>


                                                        </Table>
                                                    </div>
                                                    {/* {

                                                        getSelectedforOption.length > 0 && getSelectedforOption?.map((value) => (
                                                            <>
                                                                <div className=''>
                                                                    <h5>EIDO Procedure Information</h5>
                                                                    <span>{value?.label}</span>
                                                                </div>
                                                                <div className=''>
                                                                    <h5>EIDO Code</h5>
                                                                    <span>{value?.code}</span>
                                                                </div>
                                                            </>
                                                        ))
                                                    } */}

                                                </div>
                                            </div>

                                            {
                                                disappearingButton ?
                                                    <>

                                                    </>
                                                    : <>
                                                        <div className='d-flex justify-content-end model-btn'>

                                                            <button className='send' onClick={(e) => {
                                                                e.preventDefault();
                                                                if(gettingLabel.toString()){
                                                                    toast.error(` ${gettingLabel.toString()} not have pdf file`)
                                                                    return
                                                                }
                                                                if (Object.values(getSelectedforOption).length <= 0) {
                                                                    toast.error("Please Select Document ")
                                                                    return
                                                                }
                                                                // if (getSelectedforOption.checkPdfStatus == "") {
                                                                //     toast.error("This document is without pdf file")
                                                                // }
                                                                else
                                                                    setModalCon(false);

                                                            }}>
                                                                EMAIL TO PATIENT</button>
                                                        </div>
                                                    </>
                                            }

                                        </form>
                                    </Container>
                                </div>

                                <div className="d-flex justify-content-end  position-help">
                                    {/* <a href="mailto:rohan.vasundhara19@gmail.com"><AiOutlineQuestionCircle  />Help </a> */}
                                    <button className='help-btn mx-4 mb-0 sm-mb-5'> <a className='text-white text-decoration-none' href="mailto:kayla.blewett@eidohealthcare.co.za"><AiOutlineQuestionCircle />Help </a></button>
                                </div>
                            </>
                            :
                            <>
                                {/* modal 2 ================ */}

                                <div className='send-search'>
                                    <Container className=''>
                                        <h4>Search & Send</h4>
                                    </Container>
                                </div>
                                <div className='main-model-body'>


                                    {
                                        isLoading ? <div className='lodear-box'><Spinner animation="border" className='loader' role="status">
                                            {/* <span className="visually-hidden">Loading...</span> */}
                                        </Spinner>  </div> : <></>
                                    }



                                    <Container>
                                        {/* <form onSubmit={handleSubmit}> */}
                                        <div className="artical">
                                            <h5>Add the patient’s name and contact details</h5>
                                        </div>

                                        <form action="" className='model-form'>
                                            <Row>
                                                <Col lg={6}>
                                                    <div className="input-feild">
                                                        <label htmlFor="">First Name*</label><br />
                                                        <input type="text" name='first_name' required onChange={onChange} maxLength={50} />
                                                        <span className='float-end'>{formData.first_name.length}/50</span>
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className="input-feild">
                                                        <label htmlFor="">Last Name*</label><br />
                                                        <input type="text" name='last_name' required onChange={onChange} maxLength={50} />
                                                        <span className='float-end'>{formData.last_name.length}/50</span>
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className="input-feild" >
                                                        <label htmlFor="">Email Address*</label><br />
                                                        <input type="text" name='email' required onChange={onChange} />
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className="input-feild">
                                                        <label htmlFor="">Bcc</label><br />
                                                        <input type="text" name='bcc' onChange={onChange} />
                                                        <span className='float-start'>Send a copy to your own address for audit purpose</span>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <div className='d-flex justify-content-end mt-2'>
                                                <button className='back-btn ' onClick={() => {
                                                    setModalCon(!false);
                                                    setGetSelectedOption({})
                                                }}>
                                                    Back
                                                </button>
                                                <button onClick={handleSubmit} className='send' >SEND TO PATIENT</button>
                                            </div>
                                        </form>
                                        <div className="artical artical-two">
                                            <div className='d-flex align-items-center'>
                                                <span> <BiErrorCircle /></span>
                                                <p>   Once the article has been send to the patient, no data is stored by EIDO. All data is <br /> instantly purged and no records are retained.</p>
                                            </div>
                                        </div>
                                        {/* </form> */}
                                    </Container>
                                </div>
                                {/* <button className='help-btn position-help'> <AiOutlineQuestionCircle /> Help</button> */}
                                <div className="d-flex justify-content-end  position-help">
                                    {/* <a href="mailto:rohan.vasundhara19@gmail.com"><AiOutlineQuestionCircle  />Help </a> */}
                                    <button className='help-btn mx-4 mb-0 sm-mb-5'> <a className='text-white text-decoration-none' href="mailto:kayla.blewett@eidohealthcare.co.za"><AiOutlineQuestionCircle />Help </a></button>
                                </div>
                            </>
                    }

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Patientmodel

