import React, { useState, navigate, useContext, createContext, useEffect } from 'react'
import logo from '../image/logo.svg'
import passwordimg from '../image/Homeimg/password.svg'
import emailimg from '../image/Homeimg/email.svg'
// import { MdOutlineMailOutline } from "react-icons/md"
// import { RiLockUnlockFill } from "react-icons/ri"
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai"
import { Link, useNavigate } from 'react-router-dom'
import { adminLogin, ForgotPassword } from '../API/api'
import { toast } from "react-toastify";
import validator from 'validator';
import { AuthState } from '../context/authContext'
import { Button, Spinner } from 'react-bootstrap'
export const UserContext = createContext();
function Loginpage() {
    const { saveResponse, setSaveResponse } = AuthState()
    const navigate = useNavigate()
    const [showpass, setShowpass] = useState(false)
    const [loginInfo, setLoginInfo] = useState(" ")
    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });
    const [disabled, setDisabled] = useState(false)

    const [forgetEmail, setForgetEmail] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [forgetShow, setForgetShow] = useState(false)
    // const { email, password } = formData;
    // const onChange = (e) => {
    //     setFormData((prevState) => ({
    //         ...prevState,
    //         [e.target.name]: e.target.value,
    //     }));
    // };
    const click = () => {
        setShowpass(!showpass)
    }
    // console.log(forgetShow,"PP");

    // useEffect(() => {
    //     setEmail("")
    //     setPassword("")
    //     setFormData({})
    //     setForgetEmail("")
    //   }, [forgetShow])

    const login = async (email, password) => {

        const loginRes = await adminLogin(email, password)
        // console.log(loginRes, "loginres");
        if (loginRes.status === 1) {
            localStorage.setItem("token", loginRes.token);
            localStorage.setItem("email", `${email}`);
            localStorage.setItem("name", `${loginRes.name}`)
            localStorage.setItem("hospital", `${loginRes.hospital}`)
            localStorage.setItem("first_alpha", `${loginRes.first_alpha}`)
            setLoginInfo(loginRes)
            setSaveResponse(loginRes)
            toast.success(loginRes.message)
            navigate("/Home")
        }
        else if (!validator.isEmail(email.trim())) {
            toast.error("Please enter valid email address")
            return
        }
        else {
            toast.error("Please fill correct details")
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        // console.log(email,password,"gtgugdj");
        if (!email.trim() || !password.trim()) {
            toast.error("Please fill Details")
            return
        }
        await login(email, password)
    }
    const handleSubmitForgot = async (e) => {
        e.preventDefault()
        // console.log(forgetEmail);
        if (!forgetEmail.trim()) {
            toast.error("Please fill field")
            return
        }
        setDisabled(true)
        const resForget = await ForgotPassword(forgetEmail)
        // console.log(resForget);
        const { status, message } = resForget

        if (status === 1) {

            toast.success(message)
            navigate("/")
        } else {
            setForgetEmail("")

            toast.error(message)
        }
        setDisabled(false)

        setForgetEmail("")
        setForgetShow(false)
        // setFormData({})
    }
    // console.log(forgetEmail, "forget");
    // console.log(forgetShow, "PP");

    // console.log(loginInfo, "PPP");
    return (
        <div>
            <div className="login-main-box">
                <div className="login-box">

                    <div className="logo">
                        <img src={logo} alt="logo" />
                    </div>

                    <div className="main-form">
                        <h1>Dashboard Login</h1>

                        {
                            forgetShow ?
                                <>
                                    <form
                                        //  onSubmit={handleSubmitForgot}
                                        action="" className="mt-4">
                                        <div className=" input-box">
                                            <img src={emailimg} className="sms pt-2" alt="" />
                                            <input type="text" value={forgetEmail} id="" placeholder="Email Address" required onChange={(e) => setForgetEmail(e.target.value)} />
                                        </div>



                                        <div className="logo d-flex justify-content-between align-items-center">
                                            {/* <a href='' className='forget-pass'>Login?</a> */}
                                            {/* <Link to="/">    */}
                                            <button onClick={handleSubmitForgot} className='submit-btn order-2' disabled={disabled} > Send Mail
                                                {
                                                    // disabled ? <Spinner animation="border" role="status">
                                                    //     <span className="visually-hidden">Loading...</span>
                                                    // </Spinner>:<></>

                                             }
                                            </button>
                                            <button className="forgot_pass  p-0 order-1" onClick={() => setForgetShow(false)}>Login?</button>

                                            {/* </Link> */}
                                        </div>

                                    </form>
                                </>

                                :
                                <>
                                    <form
                                        // onSubmit={handleSubmit}
                                        action="" className="mt-4">
                                        <div className=" input-box">
                                            <img src={emailimg} className="sms pt-2" alt="" />
                                            <input type="text" name="" value={email} id="" placeholder="Email Address" required onChange={(e) => setEmail(e.target.value)} />
                                        </div>

                                        <div className="input-box">
                                            <img src={passwordimg} className="sms" alt="" />
                                            <input type={(showpass === false) ? "password" : "text"} value={password} name="password" id="" placeholder="password" required onChange={(e) => { setPassword(e.target.value) }} />
                                            {
                                                (showpass === false) ? <AiFillEyeInvisible className='show-pass' onClick={click} /> :
                                                    <AiFillEye className='show-pass' onClick={click} />
                                            }


                                        </div>

                                        <div className="logo d-flex justify-content-between align-items-center">
                                            {/* <a href='' className='forget-pass'>Forgot password?</a> */}
                                            <button onClick={handleSubmit} className='submit-btn order-2'> Login </button>
                                            <button className="forgot_pass p-0 order-1" onClick={() => setForgetShow(true)}>Forgot Password?</button>

                                        </div>

                                    </form>
                                </>
                        }



                        <p>By Logging in you accept the  <a className='text-decoration-none' href='https://support.eidohealthcare.com/support/solutions/articles/80000853002'><span>EIDO Terms of Use</span></a>, the use of essential cookies and warrant that you are authorised to access the EIDO Consent Dashboard. </p>
                    </div>


                </div>

            </div>

        </div>
    )
}

export default Loginpage