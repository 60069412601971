import React, { useState } from 'react'
import Layout from '../component/Layout'
import { AuthState } from '../context/authContext';
// import cardimg from "../image/Homeimg/cardimg.svg"
import cardimg from "../image/Homeimg/cardimg.png"
import Patientmodel from './Patientmodel';

function Home() {
    const {modalShow, setModalShow} = AuthState()
    // const [modalShow, setModalShow] = useState(false);

    const handleToggle = (_potid) => {
        setModalShow(!modalShow);
    };
    return (
        <Layout>
            <div className='home-page'>
                <div className="discharg-box shadow-lg">
                    <div className="img-box">
                        <img src={cardimg} alt="" className='img-fluid' />
                    </div>
                    <div className="content-box">
                        <div className='desc-box'>
                        <h3>Discharge Document</h3>
                        <p>Search for a patient discharge PDF document, then email this directly to your patient.</p>
                        <button className='send desc-send' onClick={() => handleToggle()} >Send Discharge Document</button>
                        </div>
                    </div>
                </div>
                <Patientmodel showVal={modalShow} handleToggle={handleToggle} />
            </div>
        </Layout>
    )
}

export default Home